import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { options } from "../components/parser"
import parse from "html-react-parser"
import { TextCard, SoliWhite, SoliCard, BigText, Text } from "../components/styles"
import { SproutFloat } from "../components/headerlinks"

const RightSide = ({
  data: {
    id,
    html,
    frontmatter: { title, bild, bildtext },
  },
}) => (
  <SoliCard id={id}>
    <SproutFloat />
    <SoliWhite>{title}</SoliWhite>
    <BigText>{parse(html, options)}</BigText>
  </SoliCard>
)

const Idee = ({ data }) => {
  return (
    <Layout
      /* leftside={<LeftSide data={ideelinks()}/>} */
      rightside={<RightSide data={data.Ideerechts} />}
    >
      <TextCard>
        <Text>
          {parse(data.Idee.html, options)}
        </Text>
      </TextCard>
    </Layout>
  )
}

export default Idee

export const query = graphql`
  query IdeeQuery {
    Idee: markdownRemark(frontmatter: { location: { eq: "Idee" } }) {
      id
      frontmatter {
        online
      }
      html
    }
    Ideerechts: markdownRemark(
      frontmatter: { location: { eq: "Ideerechts" } }
    ) {
      id
      frontmatter {
        bild
        bildtext
        location
        online
        title
      }
      html
    }
  }
`
